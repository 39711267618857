.header {
    display: flex;
    justify-content: center;
    height: 100vh;
    color: #fff;
  }
  
  .header-content {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  
  h1 span {
    font-size: 40px;
  }
  
  .header-content h1 {
    font-size: 6rem;
    text-align: center;
    z-index: -1;
  }
  
  .header-content p {
    font-size: 1.05rem;
    display: block;
    padding-bottom: 2rem;
  }
  
  .fullscreen-video-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  .fullscreen-video-wrap video {
    min-width: 100%;
    min-height: 100%;
  }
  
  .header-overlay {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    background: #0a0a0a;
    opacity: 0.6;
  }


  @media screen and (max-width: 600px) {
    .header-content h1 {
      font-size: 4rem;
    }
  }