.section__about {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5rem;
    padding-bottom: 5rem;
}
  
  .section__about .content img {
    height: auto;
    width: 40rem;
    border-radius: 50%;
    max-width: 100%;
  }
  
  .text {
    width: 47rem;
    max-width: 100%;
    padding: 0 1rem;
  }
  
  .content {
    width: 128rem;
    max-width: 85%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }
  
  .text h1 {
    color: rgb(255, 102, 0);
    font-size: 9rem;
    margin-bottom: 20px;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
  }
  
  .text h5 {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 25px;
    margin-bottom: 25px;
    text-transform: capitalize;
    letter-spacing: 2px;
  }
  
  .text p {
    font-family: 'Source Code Pro', monospace;
    color: #c0c4d8;
    font-size: 1.8rem;
    line-height: 28px;
    letter-spacing: 1px;
    margin-bottom: 45px;
  }