@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);


.ss-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -13.5rem -18rem;
  width: 360px;
  min-height: 270px;
  font-size: 3rem;
  text-align: center;
}


.ss__a {
  display: inline-block;
  position: relative;
  float: left;
  width: 8rem;
  height: 8rem;
  margin: 0.5rem;
}

article {
  height: 100%;
  background-color: #eee;
  box-shadow: 0 0 6px -2px #000, 0 0 45px -20px #000;
  color: #212121;
  line-height: 90px;
  transform: perspective(300px) rotateY(0deg);
  transition: transform 0.4s linear, background-color 0s linear 0.2s, color 0s linear 0.2s;
}

a:hover article {
  transform: perspective(300px) rotateY(180deg);
  color: #eee;
  transition: transform 0.2s linear, background-color 0s linear 0.1s, color 0s linear 0.1s;
}

a:nth-child(1):hover article {
  color: #f5f5f5;
  background-color: #333;
}

a:nth-child(2):hover article {
  background-color: #FF0000;
}

a:nth-child(3):hover article {
  background-color: #0e76a8;
}

a:nth-child(4):hover article {
  background-color: #1DA1F2;
}

a:nth-child(5):hover article {
  background-color: #833AB4;
}

a:nth-child(6):hover article {
  background-color: #ea4335;
}

a:nth-child(7):hover article {
  background-color: #CB2027;
}

a:nth-child(8):hover article {
  background-color: #075E54;
}

a:nth-child(9):hover article {
  background-color: #A82400;
}

a:nth-child(10):hover article {
  background-color: #FF5700;
}

a:nth-child(11):hover article {
  background-color: #f57d00;
}

a:nth-child(12):hover article {
  background-color: #86c9ef;
}

span {
  transition: transform 0s linear 0.2s, text-shadow 0s linear 0.2s;
  display: block;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
}

a:hover span {
  transition: transform 0s linear 0.1s, text-shadow 0s linear 0.1s;
  transform: scale(-1, 1);
  text-shadow: 0 0 50px rgba(255, 255, 255, 0.5);
}

@font-face {
  font-family: "SSSocial";
  src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/2361/ss-social-regular.eot");
  src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/2361/ss-social-regular.eot?#iefix") format("embedded-opentype"), url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/2361/ss-social-regular.woff") format("woff"), url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/2361/ss-social-regular.ttf") format("truetype"), url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/2361/ss-social-regular.svg#SSSocialRegular") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* This triggers a redraw in IE to Fix IE8's :before content rendering. */
html:hover [class^="ss-"] {
  -ms-zoom: 1;
}

.ss-icon, .ss-icon.ss-social, .ss-icon.ss-social-circle, [class^="ss-"]:before, [class*=" ss-"]:before, [class^="ss-"][class*=" ss-social"]:before, [class*=" ss-"][class*=" ss-social"]:before, [class^="ss-"].right:after, [class*=" ss-"].right:after, [class^="ss-"][class*=" ss-social"].right:after, [class*=" ss-"][class*=" ss-social"].right:after {
  font-family: "SSSocial";
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga"1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.ss-icon.ss-social-circle, [class^="ss-"].ss-social-circle:before, [class*=" ss-"].ss-social-circle:before, [class^="ss-"].ss-social-circle.right:after, [class*=" ss-"].ss-social-circle.right:after {
  font-weight: bold;
}

[class^="ss-"].right:before, [class*=" ss-"].right:before {
  display: none;
  content: '';
}


/* Footer */

.footer {
  margin-top: 400px;
  display: flex;
  justify-content: center;
}

.footer p {
  font-size: 0.85rem;
  font-family: 'Poppins', sans-serif;
  color: white;
}

/* Footer einde */


/* Media querries */

@media screen and (max-width: 750px) and (min-width: 651px) {
  .list li a {
    font-size: 1.4rem;
  }

  .list {
    height: 30%;
    width: 50%;
  }
}

@media screen and (max-width: 650px) {
  .list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .list li {
    margin-bottom: 10px;
    position: relative;
  }

  .list a {
    font-size: 1rem;
  }
}

/* Media querries einde*/