.section__footer {
    width: 100vw;
    height: 15vh;

}

.footer__lower {
    height: 100%;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    background: hsl(0, 0%, 10%);
    align-items: center;
    color: grey;
    padding-left: 3rem;
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    flex-wrap: wrap;
}

.footer__lower--p {
    display: flex;
    gap: 3rem;
    padding-right: 3rem;
}

@media screen and (max-width: 550px) {
    .footer__lower {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
    }

    .footer__lower--p {
        padding: 0  ;
    }
}