@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');


/* Blog */



.container {
    max-width: 1068px;
    margin: auto;
    width: 100%;
    padding: 3rem 0 2rem;
    
}

.home {
    width: 100%;
    min-height: 400px;
    background: #212121; /* Fallback */
    animation: color 9s infinite linear;
    display: grid;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    z-index: 99;
}

.home-text {
    color: #fff;
    text-align: center;
}

.home-title {
    font-size: 3.5rem;
}

.home-subtitle {
    font-size: 1rem;
    z-index: 99;
    font-weight: 400;
}

.post-filter {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1.5rem;
    margin-top: 2rem !important;
}

.filter-item {
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    color: white;
}

.active-filter--click {
    background: rgb(255, 102, 0);
    color: #fff;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    color: white;
}

.post {
    font-family: 'Poppins', sans-serif;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, auto));
    justify-content: center;
    gap: 1.5rem;
}

.post-box {
    background: #333;
    box-shadow: 0 4px 14px hsl(355deg 25% 15% / 10%);
    padding: 15px;
    cursor: pointer;
    border-radius: 0.5rem;
}

.post-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
    border-radius: 0.5rem;
}

.category {
    font-size: 0.9rem;
    font-weight: 500;
    text-transform: uppercase;
    color: #fd8f44;
}

.post-title {
    font-size: 1.8rem;
    font-weight: 600;
    color: white;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-decoration: none;
}

.post-date {
    display: flex;
    font-size: 0.875rem;
    color: white;
    text-transform: uppercase;
    font-weight: 400;
    margin-top: 4px;
}

.post-description {
    font-size: 0.9rem;
    color: white;
    line-height: 1.5rem;
    margin: 5px 0 10px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.profile {
    display: flex;
    align-items: center;
    gap: 10px;
}

.profile-img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

.profile-name {
    font-size: 0.82rem;
    font-weight: 500;
    color: white;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
}

.footer p {
    font-size: 0.938rem;
    color: white;
}

.social {
    display: flex;
    align-items: center;
    column-gap: 1rem;
}

.social .bx {
    font-size: 1.4rem;
    color: white;
}

.social .bx:hover {
    color: orange;
    transition: 0.3s all linear;
}

.post-header {
    width: 100%;
    height: 500px;
    background: #333;
}

.post-container {
    max-width: 800px;
    margin: auto;
    width: 100%;
}

.header-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem !important;
}

.back-home {
    color: rgb(255, 102, 0);;
    font-size: 0.9rem;
    text-decoration: none;
    cursor: pointer;
}

.header-title {
    width: 90%;
    font-size: 2.6rem;
    color: #fff;
    text-align: center;
    margin-bottom: 1rem;
}

.header-img {
    width: 100%;
    height: 420px;
    object-fit: cover;
    object-position: center;
}

.post-content {
    margin-top: 10rem !important;
}

.sub-heading {
    font-size: 1.2rem;
    color: white;
}

.post-text {
    font-size: 1rem;
    line-height: 1.7rem;
    color: white;
    margin: 1rem 0;
    text-align: justify;
}

.share {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    row-gap: 1rem;
}

.share-title {
    font-size: 1.1rem;
}

/* Blog einde */

.gotopbtn .fa-arrow-up {
    position: fixed;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    z-index: 2;
    bottom: 40px;
    right: 50px;
    text-decoration: none;
    text-align: center;
    line-height: 50px;
    color: white;
    font-size: 22px;
    background: rgb(255, 102, 0);
}

/* Media queries */

@media (max-width: 1060px) {
    .container {
        margin: 0 auto;
        width: 95%;
    }

    .home-text {
        width: 100%;
    }
}

@media (max-width: 800px) {

    .post-container {
        margin: 0 auto;
        width: 95%;
    }
}

@media (max-width: 768px) {
    

    .header-content {
        margin-top: 3rem !important;
    }

    .home {
        min-height: 380px;
    }

    .header-title {
        font-size: 2rem;
    }

    .header-img {
        height: 370px;
    }

    .post-header {
        height: 435px;
    }

    .post-content {
        margin-top: 9rem !important;
    }
}

@media (max-width: 570px) {
    .post-header {
        height: 390px;
    }

    .header-title {
        width: 100%;
    }

    .header-img {
        height: 340px;
    }

    .footer {
        flex-direction: column;
        row-gap: 1rem;
        padding: 20px 0;
        text-align: center;
    }
}

@media (max-width: 396px) {
    .home-title {
        font-size: 2rem;
    }

    .home-subtitle {
        font-size: 0.9rem;
    }

    .home {
        min-height: 300px;
    }

    .post-box {
        padding: 10px;
    }

    .header-title {
        font-size: 1.4rem;
    }

    .header-img {
        height: 240px;
    }

    .post-header {
        height: 335px;
    }

    .post-content {
        margin-top: 5rem !important;
    }

    .post-text {
        font-size: 0.875rem;
        line-height: 1.5rem;
        margin: 10px 0;
    }
}

@media screen and (max-width: 750px) and (min-width: 651px) {
    .list li a {
        font-size: 1.4rem;
    }

    .list {
        height: 30%;
        width: 50%;
    }
}

@media screen and (max-width: 650px) {
    .list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .list li {
        margin-bottom: 10px;
        position: relative;
    }

    .list a {
        font-size: 1rem;
    }
}

/* Media queries einde */
