.projects__section {
  align-items: flex-start;
  display: flex;
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  padding: 64px 24px;
}

.h1__card {
  padding: 5rem 0rem 3rem 0rem;
  font-size: 400%;
  justify-content: center;
  display: flex;
  font-family: sans-serif;
  color: white;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  max-width: 1200px;
  width: 100%;
}

.card {
  list-style: none;
  position: relative;
  cursor: pointer;
}

.card:before {
  content: '';
  display: block;
  padding-bottom: 150%;
  width: 100%;

}

.card__background {
  background-size:cover;
  background-position: center;
  border-radius: 24px;
  bottom: 0;
  /* filter: brightness(0.75) saturate(1.2) contrast(0.85); */
  left: 0;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  position: absolute;
  right: 0;
  top: 0;
  transform-origin: center;
  transform: scale(1) translateZ(0);
  transition:
    filter 200ms linear,
    transform 200ms linear;
}

.model-p {
  font-size: 1.5rem;
}

.card:hover .card__background {
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
}

.card-info {
  background-color: #212121;
  height: 7.5rem;
  font-size: 2.7rem;
  gap: 5rem;
  width: 100%;
  color: white;
  border-bottom-left-radius: 1.9rem;
  border-bottom-right-radius: 1.9rem;
  display: flex;
  bottom: 0;
  position: absolute;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.card__content {
  left: 0;
  padding: 24px;
  position: absolute;
  display: flex;
  justify-content: center;
  color: white;
  top: 0;
}

.card__category {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.9rem;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.card__heading {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.9rem;
  text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
  line-height: 1.4;
  word-spacing: 100vw;
}


/* Media querries */

@media(min-width: 540px) {
  .card-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media(min-width: 960px) {
  .card-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}


.modal {
  font-family: arial;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1043;
  color: #FDEEDC;
  outline: none;
}

.model-opdracht {
  background: rgb(255, 102, 0);
  width: 20rem;
  height: 4rem;
  font-size: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.model-time {
  height: 3rem;
  display: flex;
  align-items: center;
}

.model-skills {
  display: flex;
  align-items: center;
  height: 4rem;
  font-size: 80%;
  gap: 1rem;
  padding-top: 2rem;
  flex-wrap: wrap;
} 

.model-html {
  outline: 1px solid white;
  padding: 0.7rem 2rem;
}

.model-skills-title {
  display: flex;
  align-items: center;
  padding-top: 2rem;
}

.model-gap {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-top: 2rem;
}

.model-show {
  width: 85vw;
  height: 52vh;
  background-image: linear-gradient(216deg, rgba(77, 77, 77,0.05) 0%, rgba(77, 77, 77,0.05) 25%,rgba(42, 42, 42,0.05) 25%, rgba(42, 42, 42,0.05) 38%,rgba(223, 223, 223,0.05) 38%, rgba(223, 223, 223,0.05) 75%,rgba(36, 36, 36,0.05) 75%, rgba(36, 36, 36,0.05) 100%),linear-gradient(44deg, rgba(128, 128, 128,0.05) 0%, rgba(128, 128, 128,0.05) 34%,rgba(212, 212, 212,0.05) 34%, rgba(212, 212, 212,0.05) 57%,rgba(25, 25, 25,0.05) 57%, rgba(25, 25, 25,0.05) 89%,rgba(135, 135, 135,0.05) 89%, rgba(135, 135, 135,0.05) 100%),linear-gradient(241deg, rgba(55, 55, 55,0.05) 0%, rgba(55, 55, 55,0.05) 14%,rgba(209, 209, 209,0.05) 14%, rgba(209, 209, 209,0.05) 60%,rgba(245, 245, 245,0.05) 60%, rgba(245, 245, 245,0.05) 69%,rgba(164, 164, 164,0.05) 69%, rgba(164, 164, 164,0.05) 100%),linear-gradient(249deg, rgba(248, 248, 248,0.05) 0%, rgba(248, 248, 248,0.05) 32%,rgba(148, 148, 148,0.05) 32%, rgba(148, 148, 148,0.05) 35%,rgba(202, 202, 202,0.05) 35%, rgba(202, 202, 202,0.05) 51%,rgba(181, 181, 181,0.05) 51%, rgba(181, 181, 181,0.05) 100%),linear-gradient(92deg, hsl(214,0%,11%),hsl(214,0%,11%));
  padding: 2rem;
}

.model-show-2 {
  width: 85vw;
  min-height: 25vh;
  background-image: linear-gradient(216deg, rgba(77, 77, 77,0.05) 0%, rgba(77, 77, 77,0.05) 25%,rgba(42, 42, 42,0.05) 25%, rgba(42, 42, 42,0.05) 38%,rgba(223, 223, 223,0.05) 38%, rgba(223, 223, 223,0.05) 75%,rgba(36, 36, 36,0.05) 75%, rgba(36, 36, 36,0.05) 100%),linear-gradient(44deg, rgba(128, 128, 128,0.05) 0%, rgba(128, 128, 128,0.05) 34%,rgba(212, 212, 212,0.05) 34%, rgba(212, 212, 212,0.05) 57%,rgba(25, 25, 25,0.05) 57%, rgba(25, 25, 25,0.05) 89%,rgba(135, 135, 135,0.05) 89%, rgba(135, 135, 135,0.05) 100%),linear-gradient(241deg, rgba(55, 55, 55,0.05) 0%, rgba(55, 55, 55,0.05) 14%,rgba(209, 209, 209,0.05) 14%, rgba(209, 209, 209,0.05) 60%,rgba(245, 245, 245,0.05) 60%, rgba(245, 245, 245,0.05) 69%,rgba(164, 164, 164,0.05) 69%, rgba(164, 164, 164,0.05) 100%),linear-gradient(249deg, rgba(248, 248, 248,0.05) 0%, rgba(248, 248, 248,0.05) 32%,rgba(148, 148, 148,0.05) 32%, rgba(148, 148, 148,0.05) 35%,rgba(202, 202, 202,0.05) 35%, rgba(202, 202, 202,0.05) 51%,rgba(181, 181, 181,0.05) 51%, rgba(181, 181, 181,0.05) 100%),linear-gradient(92deg, hsl(214,0%,11%),hsl(214,0%,11%));
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.model-icons {
  height: 12rem;
  width: 5rem;
  display: flex;
  align-items:center;
  font-size: 3rem;
}

#model-i {
  color: white;
}

.model-button {
  padding: 1.5rem 7.5rem;
  font-size: 1.8rem;
  float: right;
  background: rgb(255, 102, 0);
  text-align: center;
}

.backdrop {
  position: fixed;
  z-index: 1042;
  top: 0;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.95;
}

.modal-title {
  font-weight: 500;
  font-size: 2rem;
  height: 8rem;
  display: flex;
  align-items: center;
}

.modal-header {
  padding: 1.2rem;
}

.modal-desc {

}

button {
  cursor: pointer;
  font-size: 1rem;
  padding: 10px 12px;
  border-radius: 5px;
  border: none;
}

@media screen and (max-width: 780px) {
    .model-button {
      padding: 0;
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.4rem;
      top: 7%;
      right: 3%;
    }

    .esc {
      display: none;
    }
}

.esc {
  font-style: italic;
  font-size: 1.4rem;
  color: rgb(61, 61, 61);
}


@media screen and (max-width: 600px) {
  .modal-title {
    font-size: 1.8rem;
  }
}