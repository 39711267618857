@font-face {
	font-family: 'Euclid';
	src: url("./Files/euclid-circular-a-cufonfonts/Euclid\ Circular\ A\ Regular.ttf");
}

.header__nav {
	position: fixed;
	font-family: 'Euclid';
	width: 100%;
	z-index: 1;
	padding: 5rem;
	color: #eee;
}

nav a {
	margin: 0 2rem;
	text-decoration: none;
	color: white;
}

nav a:hover {
	color: hsl(10, 100%, 78%);
}

.header__nav .nav__btn {
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: #fff;
	visibility: hidden;
	opacity: 0;
	font-size: 4rem;
}

.header__nav .nav__btn {
	visibility: visible;
	opacity: 1;
	position: absolute;
	padding: 0 0 4.7rem 2em;
	right: 4rem;
	top: 4rem;
}

.header__nav nav {
	position: fixed;
	top: 0;
	left: 0;
	font-size: 2rem;
	height: 100%;
	width: 100%;
	display: flex;
	z-index: 99;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 4.5rem;
	background: rgba(0, 0, 0, 0.98);
	transition: 1s;
	transform: translateY(-100vh);
}

nav .nav__btn--close {
	position: absolute;
	top: 4rem;
	right: 4rem;
}

.header__nav .responsive__nav {
	transform: none;
}

